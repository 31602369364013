<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'GroupsNewConnection',
  components: {
    Modal: () => import('@/components/general/Modal'),
    Action: () => import('@/components/general/Action'),
    Divisor: () => import('@/components/general/Divisor'),
    Datatable: () => import('@/components/general/Datatable'),
    Pagination: () => import('@/components/general/Pagination'),
    FilterListSearch: () => import('@/components/general/FilterListSearch')
  },

  data () {
    return {
      paramType: null,
      paramGroup: null,
      availableItems: [],
      connectedItems: [],
      pagination: {
        filter: {},
        page: 1,
        actualPage: 1,
        lastPage: null,
        limit: 6
      },

      connectedPagination: {
        filter: {
          id_group: null
        },

        page: 1,
        actualPage: 1,
        lastPage: null,
        limit: 6
      }
    }
  },

  computed: {
    ...mapState([ 'fetching' ]),
    ...mapGetters([
      'usersList',
      'solutionsList',
      'programsList',
      'thematicPagesList',
      'groupsUsers',
      'groupsSolutions',
      'groupsLearningPaths',
      'groupsPrograms',
      'groupsThematicPages'
    ]),

    getTypeTextAvailable () {
      return this.$t(`groups.connections.new:datatable.header.${this.paramType}.available`)
    },

    getTypeTextSelected () {
      return this.$t(`groups.connections.new:datatable.header.${this.paramType}.selected`)
    }
  },

  watch: {
    'availableItems' () {
      this.setFetching(false)
    },

    'pagination.page' () {
      this.fetchData()
    },

    'connectedPagination.page' () {
      this.fetchConnections()
    }
  },

  created () {
    this.paramType = this.$route.params.type
    this.paramGroup = this.$route.params.group || this.$route.params.id
    const groupId = this.paramGroup.id ? this.paramGroup.id : this.paramGroup

    this.connectedPagination.filter.id_group = groupId
    this.pagination.filter.not_in_group = groupId
    this.fetchData()
    this.fetchConnections()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetUsersList',
      'attemptGetSolutionsList',
      'attemptGetLearningPathsList',
      'attemptGetProgramsList',
      'attemptGetThematicPagesList',
      'attemptCreateConnection',
      'attemptGetGroupsUsers',
      'attemptGetGroupsSolutions',
      'attemptGetGroupsLearningPaths',
      'attemptGetGroupsPrograms',
      'attemptGetGroupsThematicPages'
    ]),

    prevPage (pagination) {
      if (pagination.page > 1) {
        pagination.page -= 1
      }
    },

    nextPage (pagination) {
      pagination.page += 1
    },

    firstPage (pagination) {
      pagination.page = 1
    },

    lastPage (pagination) {
      pagination.page = pagination.lastPage
    },

    leave () {
      this.$router.go(-1)
    },

    getUsers () {
      this.setFetching(true)

      this.attemptGetUsersList(this.pagination)
        .then((data) => {
          this.availableItems = this.usersList
          this.updatePagination(data)
        })
    },

    getSolutions () {
      this.setFetching(true)

      this.attemptGetSolutionsList(this.pagination)
        .then(data => {
          this.availableItems = this.solutionsList
          this.updatePagination(data)
        })
    },

    getLearningPaths () {
      this.setFetching(true)

      this.attemptGetLearningPathsList(this.pagination)
        .then(data => {
          this.availableItems = data.data
          this.updatePagination(data)
        })
    },

    getPrograms () {
      this.setFetching(true)

      this.attemptGetProgramsList(this.pagination)
        .then(data => {
          this.availableItems = this.programsList
          this.updatePagination(data)
        })
    },

    getThematicPages () {
      this.setFetching(true)

      this.attemptGetThematicPagesList(this.pagination)
        .then(data => {
          this.availableItems = this.thematicPagesList
          this.updatePagination(data)
        })
    },

    updatePagination (data) {
      this.pagination.page = data.actualPage
      this.pagination.lastPage = data.lastPage
      this.pagination.nextPage = data.nextPage
      this.pagination.previousPage = data.previousPage
      this.pagination.total = data.total
    },

    updateConnectedPagination (data) {
      this.connectedPagination.page = data.actualPage
      this.connectedPagination.lastPage = data.lastPage
      this.connectedPagination.nextPage = data.nextPage
      this.connectedPagination.previousPage = data.previousPage
      this.connectedPagination.total = data.total
    },

    fetchData () {
      if (this.paramType === 'user') {
        this.getUsers()
        this.connectedItems = this.groupsUsers
      } else if (this.paramType === 'solution') {
        this.getSolutions()
        this.connectedItems = this.groupsSolutions
      } else if (this.paramType === 'path') {
        this.getLearningPaths()
        this.connectedItems = this.groupsLearningPaths
      } else if (this.paramType === 'program') {
        this.getPrograms()
        this.connectedItems = this.groupsPrograms
      } else if (this.paramType === 'thematic_page') {
        this.getThematicPages()
        this.connectedItems = this.groupsThematicPages
      }
    },

    fetchConnections () {
      this.setFetching(true)

      if (this.paramType === 'user') {
        this.attemptGetGroupsUsers(this.connectedPagination)
          .then(data => {
            this.updateConnectedPagination(data)
            this.connectedItems = data.data
          })
          .finally(() => {
            this.setFetching(false)
          })
      } else if (this.paramType === 'solution') {
        this.attemptGetGroupsSolutions(this.connectedPagination)
          .then(data => {
            this.updateConnectedPagination(data)
            this.connectedItems = data.data
          })
          .finally(() => {
            this.setFetching(false)
          })
      } else if (this.paramType === 'path') {
        this.attemptGetGroupsLearningPaths(this.connectedPagination)
          .then(data => {
            this.updateConnectedPagination(data)
            this.connectedItems = data.data
          })
          .finally(() => {
            this.setFetching(false)
          })
      } else if (this.paramType === 'program') {
        this.attemptGetGroupsPrograms(this.connectedPagination)
          .then(data => {
            this.updateConnectedPagination(data)
            this.connectedItems = data.data
          })
          .finally(() => {
            this.setFetching(false)
          })
      } else if (this.paramType === 'thematic_page') {
        this.attemptGetGroupsThematicPages(this.connectedPagination)
          .then(data => {
            this.updateConnectedPagination(data)
            this.connectedItems = data.data
          })
          .finally(() => {
            this.setFetching(false)
          })
      }
    },

    makeConnection (item) {
      const idTab = this.getTabId()
      // eslint-disable-next-line prefer-const
      let payload = {
        groupId: this.paramGroup.id,
        tab: this.formatTypeToUrl(this.paramType),
        data: {}
      }

      payload.data[idTab] = item.id

      this.setFetching(true)

      this.attemptCreateConnection(payload)
        .then(() => {
          this.setFeedback({ message: this.$t('groups:feedback.syncing.success') })
          this.fetchData()
          this.fetchConnections()
        })
        .catch(data => {
          this.setFeedback({ message: this.$t(`groups:feedback.${data.data.error.message}`) })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    getTabId () {
      const types = {
        user: 'id_user',
        solution: 'id_solution',
        path: 'id_learning_path',
        program: 'id_program',
        thematic_page: 'id_thematic_page'
      }

      return types[this.paramType]
    },

    formatTypeToUrl (type) {
      const types = {
        user: 'users',
        solution: 'solutions',
        path: 'learning_paths',
        program: 'programs',
        thematic_page: 'thematic_pages'
      }

      return types[type]
    },

    getItemName (item) {
      const types = {
        user: 'user',
        solution: 'solution',
        path: 'learningPath',
        program: 'program',
        thematic_page: 'thematicPage'
      }

      return item[types[this.paramType]].name
    },

    searchItem (search) {
      this.setFetching(true)

      this.pagination.query = {
        name: search
      }

      this.fetchData()
    },

    searchConnection (search) {
      this.setFetching(true)

      this.connectedPagination.query = {
        name: search
      }

      this.fetchConnections()
    }
  }

}
</script>

<template>
  <Modal
    :active="true"
    :cancel-label="$t('global:back')"
    @close="leave()"
  >
    <div class="modal-form modal-group-connections-new">
      <span class="modal-subtitle">{{ $t('groups.connections.new:header.title') }}</span>

      <h2 class="modal-title">
        {{ paramGroup.name }}
      </h2>

      <p class="modal-description">
        {{ $t(`groups.connections.new:header.description.${paramType}`) }}
      </p>

      <div class="connection-tables">
        <div class="available-items">
          <Datatable
            :items="availableItems"
            hide-horizontal-line
            dark
          >
            <template slot="thead">
              <th
                class="th"
                width="300"
              >
                {{ getTypeTextAvailable }}
              </th>

              <th
                class="th"
                width="40"
              />
            </template>

            <template slot="tsearch">
              <FilterListSearch
                slot="search"
                :prepend-label="$t('global:search')"
                :hide-error-details="true"
                class="filter-search"
                dark
                @search="searchItem"
              />
            </template>

            <template
              slot="tbody"
              slot-scope="props"
            >
              <tr
                class="available-item"
                @click="makeConnection(props.item)"
              >
                <td
                  class="td td-full"
                >
                  <span class="td-text bolder">{{ props.item.name }}</span>
                </td>
                <td
                  class="td td-button td-small text-right"
                >
                  <Action
                    type="button"
                    icon="keyboard_arrow_right"
                    flat
                  />
                </td>
              </tr>
            </template>
          </Datatable>

          <Pagination
            :active-page="pagination.page"
            :page-count="pagination.lastPage"
            dark
            @firstPage="firstPage(pagination)"
            @lastPage="lastPage(pagination)"
            @nextPage="nextPage(pagination)"
            @previousPage="prevPage(pagination)"
          />
        </div>

        <Divisor orientation="vertical" />

        <div class="connected-items">
          <Datatable
            :items="connectedItems"
            hide-horizontal-line
            dark
          >
            <template slot="thead">
              <th
                class="th"
                width="300"
              >
                {{ getTypeTextSelected }}
              </th>
            </template>

            <template slot="tsearch">
              <FilterListSearch
                slot="search"
                :prepend-label="$t('global:search')"
                :hide-error-details="true"
                class="filter-search"
                dark
                @search="searchConnection"
              />
            </template>

            <template
              slot="tbody"
              slot-scope="props"
            >
              <tr>
                <td
                  class="td td-full"
                >
                  <span class="td-text bolder">{{ getItemName(props.item) }}</span>
                </td>
              </tr>
            </template>
          </Datatable>

          <Pagination
            :active-page="connectedPagination.page"
            :page-count="connectedPagination.lastPage"
            dark
            @firstPage="firstPage(connectedPagination)"
            @lastPage="lastPage(connectedPagination)"
            @nextPage="nextPage(connectedPagination)"
            @previousPage="prevPage(connectedPagination)"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss">
.modal-description {
  line-height: 1.5em;
  font-style: italic;
  max-width: 720px;
  color: white;
  margin: 0 auto;
}

.connection-tables {
  display: flex;
  gap: 24px;
}

.available-items {
  width: 50%;
}

.available-items .available-item {
  cursor: pointer;
  transition: background 200ms ease;
}

.available-items .available-item:hover {
  background: rgba(255, 255, 255, .04);
}

.available-items .btn-flat {
  margin-bottom: 0;
}

.filter-search .filter-search-form {
  margin: 0 auto 16px;
}

.filter-search input {
  color: white;
}

.connected-items {
  width: 50%;
}

.connected-items tr {
  height: 40px;
}
</style>
