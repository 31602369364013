import { render, staticRenderFns } from "./GroupsNewConnection.vue?vue&type=template&id=50385998&"
import script from "./GroupsNewConnection.vue?vue&type=script&lang=js&"
export * from "./GroupsNewConnection.vue?vue&type=script&lang=js&"
import style0 from "./GroupsNewConnection.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports